import { useState, useEffect } from "react";
import './admin.css';
import { Vendeg } from "./vendeg";

const moment = require('moment');

export function VendegKezelo() {
  const [foglalasokvezerlo, setFoglalasok] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchFoglalasok() {
      try {
        const response = await fetch(url+"/api/admin/foglalasok", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Hálozati probléma');
        }

        const data = await response.json();
        console.log('API response:', data);
        if (Array.isArray(data)) {
          const fetchedFoglalasok = data.map(foglalas => ({
            id: foglalas.id,
            tol: moment(new Date(foglalas.tol)).format('YYYY.MM.DD'),
            ig: moment(new Date(foglalas.ig)).format('YYYY.MM.DD'),
            teljes_nev: foglalas.teljes_nev,
            vendegszam: foglalas.vendegszam,
            email: foglalas.email,
            telefonszam: foglalas.telefonszam,
            kisallat: foglalas.kisallat,
            paymentMethod: foglalas.paymentMethod,
            fizetendo: foglalas.fizetendo
          }));
          setFoglalasok(fetchedFoglalasok);
        } else {
          console.error('Nem taláható foglalás');
          setFoglalasok([]);
        }
      } catch (error) {
        setError(error.message);
        console.error('Hiba az adateléréssel', error);
        setFoglalasok([]);
      } finally {
        setLoading(false);
      }
    }

    fetchFoglalasok();
  }, []);
  
  const foglalasokk = foglalasokvezerlo.map(foglalas => (
    <Vendeg key={foglalas.id} foglalasadatok={foglalas} />
  ));

  if (loading) {
    return <div className="loading">...</div>;
  }

  if (error) {
    return <div className="error">Hiba: frissítsd az oldalad</div>;
  }

  return (
    <div className="foglalasok-container">
      {foglalasokk}
    </div>
  );
}
