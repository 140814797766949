import "./koszonjuk.css";
import React from "react";
import { useSelector } from "react-redux";

export function KoszonjukBank() {
    const formData = useSelector(state => state.booking.formData);
    return (
        <div className="koszonjuk-teljes-container">
            <div className="koszonjuk-szoveg-container">
                <h2>Köszönjük a foglalást!</h2>
                <p>
                    Mivel bankkártyás fizetést választott, kérjük, utalja el az összeget az alábbi számlaszámra:
                </p>
                <p>
                    <strong>Kezdeményezett neve:</strong> Császárné Bedő Edina
                </p>
                <p>
                    <strong>Számlaszám:</strong> HU55 1040 0803 8049 5153 4954 1000
                </p>
                <p>
                    <strong>Közlemény:</strong> {formData.name}
                </p>
                <p>
                    <strong>Összeg:</strong> {formData.fizetendo} ft
                </p>
                <p>
                    Ha bármilyen kérdése van, kérjük, keressen minket az elküldött emailben található elérhetőségeken.
                </p>
            </div>
        </div>
    );
}
