import React from "react";
import { Header } from "./homepage/header";
import { Front } from "./homepage/front";
import { Apartman } from "./homepage/apartman";
import { YouNeed } from "./homepage/youneed";
import { Ratings } from "./homepage/ratings";
import { RoomPhotos } from "./homepage/roomphotos";
import ContactPage from "./homepage/contact";

export function Home(){


    return(
        <div>
            <Header />
            <Front />
            <Apartman />
            <YouNeed />
            <Ratings />
            <RoomPhotos />
            <ContactPage />
        </div>
    );
}