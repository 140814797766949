import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/hu'; // importálja a magyar locale-t a moment könyvtárból
import { VendegKezelo } from '../admin/vendegkezelo';

// Helyi idő beállítása a moment könyvtárral
const localizer = momentLocalizer(moment);


const messages = {
  allDay: 'Egész nap',
  previous: 'Előző',
  next: 'Következő',
  today: 'Ma',
  month: 'Hónap',
  week: 'Hét',
  day: 'Nap',
  agenda: 'Napirend',
  date: 'Dátum',
  time: 'Idő',
  event: 'Esemény',
  noEventsInRange: 'Nincs esemény ebben az időszakban.',
  showMore: total => `+ Még ${total}`
};

const Admin = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchEvents() {
      try {
        const response = await fetch(url+"/api/naptaradmin", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
           
          },
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Hálozati probléma');
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          const fetchedEvents = data.map(event => ({
            title: event.title,
            start: new Date(event.start),
            end: new Date(event.end)
          }));
          setEvents(fetchedEvents);
        } else {
          console.error('Nem taláható foglalás');
        }
      } catch (error) {
        setError(error.message);
        console.error('Hiba az adateléréssel', error);
      } finally {
        setLoading(false);
      }
    }

    fetchEvents();
  }, []);

  if (loading) {
    return <div>Várj egy picit...</div>;
  }

  if (error) {
    return <div>Hiba: frissítsd az oldalad</div>;
  }

  return (
    <div>
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        messages={messages}
      />
    </div>
    <div className='vendegkezelo-container'>
    <VendegKezelo />
    </div>
    </div>
  );
};

export default Admin;
