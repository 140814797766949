import React from "react";
import "./ratings.css";
import Bookinglogo from "./fotok/iconok/bookinglogo.png";
import Googlelogo from "./fotok/iconok/googlelogo.png";
import Szallashulogo from "./fotok/iconok/szallashu.png";

export function Ratings(){

    return (
        <div className="ratings-container">
          <div className="rating">
            <div className="score">9.1<span>/10</span></div>
            <div className="comments">14 értékelés</div>
            <div className="logo"><img src={Bookinglogo} alt="Booking.com" /></div>
          </div>
          <div className="rating">
            <div className="score">9.8<span>/10</span></div>
            <div className="comments">26 értékelés</div>
            <div className="logo"><img src={Szallashulogo}  alt="Szallas.hu" /></div>
          </div>
          <div className="rating">
            <div className="score">4.9<span>/5</span></div>
            <div className="comments">7 vélemény</div>
            <div className="logo"><img src={Googlelogo} alt="Google" /></div>
          </div>
          {/*<div className="rating">
            <div className="score">98<span>%</span></div>
            <div className="comments">2389 recommendations</div>
            <div className="logo"><img src="hostelbookers-logo.png" alt="Hostelbookers" /></div>
          </div>*/}
        </div>
      );

}