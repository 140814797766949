import "./koszonjuk.css";
import React from "react";
import { useSelector } from "react-redux";


export function KoszonjukSZEP() {
    const formData = useSelector(state => state.booking.formData);
    return (
        <div className="koszonjuk-teljes-container">
            <div className="koszonjuk-szoveg-container">
                <h2>Köszönjük a foglalást!</h2>
                <p>
                    Mivel SZÉP-kártyás fizetést választott, kérjük, utalja el az összeget az alábbi információk szerint:
                </p>
                <p>
                    <strong>Kártyatípusok:</strong> MKB, K&H, OTP
                </p>
                <p>
                    <strong>Közlemény:</strong> {formData.name}
                </p>
                <p>
                    Ha bármilyen kérdése van, kérjük, keressen minket az elküldött emailben található elérhetőségeken.
                </p>
            </div>
        </div>
    );
}
