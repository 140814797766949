// Login.js
import React, { useState } from 'react';
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import "./login.css";
import { useDispatch, useSelector } from 'react-redux';
import { setPermission } from '../../store/store';

const Login = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(state => state.permission.isAdmin);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const url = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(url+'/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({ username, password })
    });
    if (response.ok) {
      // Sikeres bejelentkezés
      console.log('Logged in successfully');
      dispatch(setPermission({ isAdmin: true }));
      navigate("/admin");
      setPassword('');
    } else {
      // Hibakezelés
      navigate("/login")
      setUsername('');
      setPassword('');
      console.error('Login failed');
      
    }
  };

  return (
    <div className="big-div">
      <div className="login-group">
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <div className="form-group">
                <label>Felhasználó:</label>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div className="form-group">
                <label>Jelszó:</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              </div>
              <div className="form-group">
                <button type="submit">Bejelentkezés</button>
              </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
