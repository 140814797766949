import React from "react";
import "./roomphotos.css";
import image1 from "./fotok/dezsa.jpg";
import image2 from "./fotok/furdoszoba.jpg";
import image3 from "./fotok/szoba.jpg";
import nappali from "./fotok/nappali.jpg";
import konyha from "./fotok/konyha.jpg";


export function RoomPhotos() {
  return (
    <div className="room-photos-container">
      <div className="header-container">
        <h1>Képek a helyiségekről</h1>
        {/*<button>View all photos</button>*/}
      </div>
      <div className="photos-grid">
        <div className="photo-item1" style={{ backgroundImage: `url(${image2})` }}></div>
        <div className="photo-item2" style={{ backgroundImage: `url(${konyha})` }}></div>
        <div className="photo-item3" style={{ backgroundImage: `url(${image3})` }}></div>
        <div className="photo-item4" style={{ backgroundImage: `url(${nappali})` }}></div>
      </div>
    </div>
  );
}
