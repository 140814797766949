import "./youneed.css";
import Dronevideo from "./lipicspartdrone.mp4";
import Legkondi from "./fotok/iconok/air-conditioner.png";
import Hegymaszas from "./fotok/iconok/hiking.png";
import Parkolas from "./fotok/iconok/parking.png";
import Dézsafürdő from "./fotok/iconok/hot-bath.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import React, { useEffect, useRef } from "react";



export function YouNeed(){
    const navigate = useNavigate();
    const [iconsIsHovered, seticonsIsHovered] = useState(false);
    const divRef = useRef(null);


    const handleClickOutside = (event) => {
        // Ha a kattintás nem a diven történt, állítsa false-ra isVisible-t
        if (divRef.current && !divRef.current.contains(event.target)) {
            seticonsIsHovered(false);
        }
    };

    useEffect(() => {
        if (iconsIsHovered) {
            // Eseményfigyelő hozzáadása, amikor a div megjelenik
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            // Eseményfigyelő eltávolítása, amikor a div eltűnik
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            // Tisztítás, ha a komponens unmountol
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [iconsIsHovered]);

    return(
        <div className="front-containerr">
            <div className="front-szovegg">
                <h1>Kikapcsolodás a természet közelében</h1>
                <div className="p-containerr">
                    <p>A pihenésed során mindent megteszünk, hogy zavartalan és kellemes legyen az itt tartózkodásod. Vendégházunk a természet közvetlen közelében helyezkedik el, ahol a racka juhok közelsége különleges élményt nyújt.</p>
                </div> 
                <div className="icons-container">
                    <div className="icon-container">
                        <img src={Legkondi} />
                        {/*<p>Légkondíció</p>*/}
                    </div>

                    <div className="icon-container">
                        <img src={Dézsafürdő} />
                        {/*<p>Dézsafürdő</p>*/}
                    </div>

                    <div className="icon-container">
                        <img src={Parkolas} />
                        {/*<p>Parkolási lehetőség</p>*/}
                    </div>

                    <div className="icon-container">
                        <img src={Hegymaszas} />
                        {/*<p>Kirándulási lehetőségek</p>*/}
                    </div>
                </div>
                {iconsIsHovered ? 
                <div className="felszereltseg-container" ref={divRef}>
                  <div class="features-container">
    <h2>Népszerű</h2>
    <ul class="features-list">
        <li><span class="icon">✔</span><span>Dézsafürdő</span></li>
        <li><span class="icon">✔</span><span>Ágynemű</span></li>
        <li><span class="icon">✔</span><span>Légkondicionálás</span></li>
    </ul>

    <h2>Konyha és étkezés</h2>
    <ul class="features-list">
        <li><span class="icon">✔</span><span>Konyhai alapkellékek</span></li>
        <li><span class="icon">✔</span><span>Étkészlet és evőeszközök</span></li>
        <li><span class="icon">✔</span><span>Tepsi</span></li>
        <li><span class="icon">✔</span><span>Grillező</span></li> 
        <li><span class="icon">✔</span><span>Bogrács</span></li>
        <li><span class="icon">✔</span><span>Szalonnasütő</span></li>
        <li><span class="icon">✔</span><span>Kávéfőző</span></li>
        <li><span class="icon">✔</span><span>Étkezőasztal</span></li>
        <li><span class="icon">✔</span><span>Fagyasztó</span></li>
        <li><span class="icon">✔</span><span>Vízforraló</span></li>
        <li><span class="icon">✔</span><span>Mikrohullámú sütő</span></li>
        <li><span class="icon">✔</span><span>Sütő</span></li>
        <li><span class="icon">✔</span><span>Hűtő</span></li>
        <li><span class="icon">✔</span><span>Tűzhely</span></li>
        <li><span class="icon">✔</span><span>Kenyérpirító</span></li>
        <li><span class="icon">✔</span><span>Borospoharak</span></li>
    </ul>

    <h2>Fürdőszoba</h2>
    <ul class="features-list">
        <li><span class="icon">✔</span><span>1 fürdőszoba</span></li>
        <li><span class="icon">✔</span><span>Törölköző</span></li>
        <li><span class="icon">✔</span><span>Hajszárító</span></li>
        <li><span class="icon">✔</span><span>Köntös (Dézsa használata esetén)</span></li>
    </ul>

    <h2>Hálószoba és ágynemű</h2>
    <ul class="features-list">
        <li><span class="icon">✔</span><span>Ágynemű</span></li>
        <li><span class="icon">✔</span><span>Ruhatároló</span></li>
        <li><span class="icon">✔</span><span>Ruhaszárító állvány</span></li>
        <li><span class="icon">✔</span><span>Plusz párnák és takarók</span></li>
        <li><span class="icon">✔</span><span>Vállfák</span></li>
        <li><span class="icon">✔</span><span>Sötétítők/árnyékolók</span></li>
    </ul>

    <h2>Szórakozás</h2>
    <ul class="features-list">
        <li><span class="icon">✔</span><span>2 TV</span></li>
    </ul>

    <h2>Gyermekeknek</h2>
    <ul class="features-list">
    <li><span class="icon">✔</span><span>Etetőszék</span></li>
        <li><span class="icon">✔</span><span>Utazóágy</span></li>
        <li><span class="icon">✔</span><span>Fürdetőkád</span></li>
        <li><span class="icon">✔</span><span>Bébiőr</span></li>
    </ul>

    <h2>Hőmérséklet szabályozás</h2>
    <ul class="features-list">
        <li><span class="icon">✔</span><span>Fűtés</span></li>
    </ul>

    <h2>Biztonság</h2>
    <ul class="features-list">
        <li><span class="icon">✔</span><span>Füstérzékelő</span></li>
    </ul>

    <h2>Szállás jellemzők</h2>
    <ul class="features-list">
        <li><span class="icon">✔</span><span>Saját bejárat</span></li>
    </ul>

    <h2>Kültér</h2>
    <ul class="features-list">
        <li><span class="icon">✔</span><span>Hátsó udvar</span></li>
        <li><span class="icon">✔</span><span>Terasz vagy erkély</span></li>
        <li><span class="icon">✔</span><span>Parkolás</span></li>
        <li><span class="icon">✔</span><span>Ingyenes parkolás a helyszínen</span></li>
        <li><span class="icon">✔</span><span>Napozóágy</span></li>
        <li><span class="icon">✔</span><span>Hintaágy</span></li>
    </ul>

</div>


                </div> : ""} 
                <button onClick={()=>{seticonsIsHovered(s => !s)}} className="felszereltseg-button">Teljes felszereltség...</button>        
            </div>
            <div className="front-video">
                <video autoPlay loop muted>
            <source src={Dronevideo} type="video/mp4" />
            Your browser does not support the video tag.
            </video>
            </div>
        </div>);
}