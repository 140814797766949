import React, { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import { hu } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { setDates } from './store/store';

function Naptar() {
    const dispatch = useDispatch();
    const startDate = useSelector(state => new Date(state.booking.startDate));
    const endDate = useSelector(state => new Date(state.booking.endDate));
    const [disabledDates, setDisabledDates] = useState([]);
    const url = process.env.REACT_APP_API_URL;

    useEffect(() => {
        async function fetchDisabledDates() {
            try {
                const response = await fetch(url+"/api/disableddates");
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (Array.isArray(data)) {
                    setDisabledDates(data);
                } else {
                    console.error('Fetched data is not an array');
                }
            } catch (error) {
                console.error('Error fetching disabled dates:', error);
            }
        }

        fetchDisabledDates();
        disabledDates.forEach(element => {
            console.log(element);
        });
    }, []);

    const now = new Date();
    const twoYearsLater = new Date(now.getFullYear() + 2, now.getMonth(), now.getDate());

    function handleChange(ranges) {
        const { startDate, endDate } = ranges.selection;
        if ((endDate - startDate) / (1000 * 60 * 60 * 24) < 1) {
            const newEndDate = new Date(startDate);
            newEndDate.setDate(newEndDate.getDate() + 1);
            dispatch(setDates({ startDate, endDate: newEndDate }));
        } else {
            dispatch(setDates({ startDate, endDate }));
        }
    }

    return (
        <div className='naptar'>
            <DateRange
                locale={hu}
                ranges={[{
                    startDate: startDate,
                    endDate: endDate,
                    key: 'selection'
                }]}
                onChange={handleChange}
                minDate={now}
                maxDate={twoYearsLater}
                disabledDates={disabledDates.map(date => new Date(date))}
            />
        </div>
    );
}

export default Naptar;
