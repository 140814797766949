import "./videobackground.css";
import React from "react";
import lipicslogo from "./lipicspartlogo-removebg-preview.png"
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Home } from "../home";
import { Foglalas } from "../../foglalas/Foglalas";
import Login from "../adminpage/login";
import Admin from "../admin";
import { useRef } from "react";
import { useEffect } from "react";
import { TypeAnimationto } from "./typeanimation";
import { useDispatch, useSelector } from 'react-redux';
import { setPermission } from "../../store/store";

export function Header(){
  const dispatch = useDispatch();
  const isAdmin = useSelector(state => state.permission.isAdmin);
  if(isAdmin){
    return( 
    <nav>
      <div className="head">
      <Link to="/"><img className="lipicslogo" src={lipicslogo}></img></Link>
  <ul className="header-bar">
    <li style={{color: "black"}}>
      Üdv Admin!
    </li>
    <li className="menu">
      <Link to="/admin">Foglalások</Link>
    </li>
    <li className="menu">
      <Link to="/admin/statisztika">Statisztika</Link>
    </li>
    
    {/*<li className="menu">
      <Link to="/login">Login</Link>
    </li>
    <li className="menu">
      <Link to="/admin">Admin</Link>
    </li>
    <li>
    {<button>Foglalok most!</button>}
    </li>*/}
  </ul>
  </div>
</nav>)
  }
  else{
    return(
        <nav>
            <div className="head">
            <Link to="/"><img className="lipicslogo" src={lipicslogo}></img></Link>
        <ul className="header-bar">
          
          <li className="menu">
            <Link to="/foglalas">Foglalás</Link>
          </li>
          {/*<li className="menu">
            <Link to="/login">Login</Link>
          </li>
          <li className="menu">
            <Link to="/admin">Admin</Link>
          </li>
          <li>
          {<button>Foglalok most!</button>}
          </li>*/}
        </ul>
        </div>
      </nav>
    );
  }
}