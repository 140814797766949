import { configureStore, createSlice } from '@reduxjs/toolkit';


const initialState = {
  startDate: new Date().toISOString(),
  endDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString(),
  formData: {
    name: '',
    email: '',
    phone: '',
    guests: 1,
    dezsa: '',
    dezsa_futes: '',       
    pet: '',
    pettype: '',
    pet_body_type: '',
    fizetendo: '0',
    paymentMethod: ''
  }
};

const permIni = {
  isAdmin: false
}

const permissionSlice = createSlice({
  name: 'permission',
  initialState: permIni,
  reducers: {
    setPermission(state, action) {
      state.isAdmin = action.payload.isAdmin;
    }
  }
});

export const { setPermission } = permissionSlice.actions;

const bookingSlice = createSlice({
  name: 'booking',
  initialState: initialState,
  reducers: {
    setDates(state, action) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setFormData(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    }
  }
});

export const { setDates, setFormData } = bookingSlice.actions;

const store = configureStore({
  reducer: {
    booking: bookingSlice.reducer,
    permission: permissionSlice.reducer
  }
});

export default store;
