import React from 'react';
import "./contact.css";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const ContactPage = () => {

    const apikey = "AIzaSyAFjAG4_L5csH_YnK-Pun61dPlegfpMdgU";

    const mapContainerStyle = {
        width: '100%',
        height: '100%',
      };
      
      const center = {
        lat: 40.712776, // Your latitude
        lng: -74.005974, // Your longitude
      };

  return (
    <div className="contact-page">
      <div className="contact-info">
        <h1>Kapcsolat</h1>
        <p>Ha további kérdéseid vannak, vedd fel velünk a kapcsolatot az alábbi módokon:</p>
        <div className="contact-grid">
          <div className="contact-item">
            <div className="icon-containerr">
                <div className="icon phone-icon"></div>
            </div>
            <div>
              <h2>Telefon</h2>
              <p>(30) 560-3474</p>
              <p>(30) 238-2644</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="icon-containerr">
                <div className="icon email-icon"></div>
            </div>
            <div>
              <h2>Email</h2>
              <p>lipicspart@gmail.com</p>
              <p>csaszar.business@gmail.com</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="icon-containerr">
                <div className="icon location-icon"></div>
            </div>
            <div>
              <h2>Cím</h2>
              <p>8868 Murarátka,</p>
              <p>Hunyadi János utca 12.</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="icon-containerr">
                <div className="icon time-icon"></div>
            </div>
            <div>
              <h2>Érkezés</h2>
              <p>Mindennap</p>
              <p>10 de. — 20 du.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d687.2001229684741!2d16.68305016963972!3d46.45264999819422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4768bbab87e52157%3A0x9e09b1c17d01f8ca!2sLipics%20Part!5e0!3m2!1shu!2shu!4v1721910791674!5m2!1shu!2shu"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Maps"
      ></iframe>
      </div>
    </div>
  );
};

export default ContactPage;
