import { useState, useEffect } from "react";
import editIcon from './edit.png';
import returnIcon from './return.png';
import deleteIcon from './delete.png';
import './admin.css';

export function Vendeg({ foglalasadatok }) {
  const [id, setId] = useState(foglalasadatok.id);
  const [tol, setTol] = useState(foglalasadatok.tol);
  const [ig, setIg] = useState(foglalasadatok.ig);
  const [nev, setNev] = useState(foglalasadatok.teljes_nev);
  const [email, setEmail] = useState(foglalasadatok.email);
  const [telefonszam, setTelefonszam] = useState(foglalasadatok.telefonszam);
  const [vendegszam, setVendegszam] = useState(foglalasadatok.vendegszam);
  const [kisallat, setKisallat] = useState(foglalasadatok.kisallat);
  const [paymentMethod, setPaymentMethod] = useState(foglalasadatok.paymentMethod);
  const [fizetendo, setFizetendo] = useState(foglalasadatok.fizetendo);
  const [edit, setEdit] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (
      telefonszam !== foglalasadatok.telefonszam ||
      vendegszam !== foglalasadatok.vendegszam ||
      email !== foglalasadatok.email ||
      nev !== foglalasadatok.teljes_nev ||
      tol !== foglalasadatok.tol ||
      foglalasadatok.ig !== ig
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [telefonszam, vendegszam, email, nev, tol, ig, foglalasadatok]);

  function handleReturnIcon() {
    setTol(foglalasadatok.tol);
    setIg(foglalasadatok.ig);
    setNev(foglalasadatok.teljes_nev);
    setEmail(foglalasadatok.email);
    setTelefonszam(foglalasadatok.telefonszam);
    setVendegszam(foglalasadatok.vendegszam);
  }

  function handleEdit() {
    setEdit(!edit);
  }

  async function handleSave() {
    const formData = {
      id,
      nev,
      tol,
      ig,
      telefonszam,
      email,
      vendegszam,
    };
    try {
      const response = await fetch(url + '/api/admin/foglalas/valtoztatas', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (response.ok && result.success) {
        setIsChanged(false);
        Object.assign(foglalasadatok, { nev, telefonszam, tol, ig, email, vendegszam });
        alert('Sikeres Mentés');
      } else {
        console.error('Hiba történt a frissítés során:', result);
      }
    } catch (error) {
      console.error('Hiba történt a kérés során:', error);
    }
  }

  async function handleDelete() {
    if (window.confirm("Biztosan törölni szeretnéd ezt a foglalást?")) {
      const formData = { id };
      try {
        const response = await fetch(url + '/api/admin/foglalas/torles', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        if (response.ok && result.success) {
          alert('Sikeres Törlés');
        } else {
          console.error('Hiba történt a törlés során:', result);
        }
      } catch (error) {
        console.error('Hiba történt a kérés során:', error);
      }
    }
  }

  return (
    <div key={foglalasadatok.id} className="vendegkezelo-item">
      <div className="field">
        <span className="field-title">ID:</span>
        <span className="field-value">{id}</span>
      </div>
      {edit ? (
        <div className="field">
          <span className="field-title">Érkezés:</span>
          <input type="date" value={tol} onChange={(e) => setTol(e.target.value)} />
        </div>
      ) : (
        <div className="field">
          <span className="field-title">Érkezés:</span>
          <span className="field-value">{tol}</span>
        </div>
      )}
      {edit ? (
        <div className="field">
          <span className="field-title">Távozás:</span>
          <input type="date" value={ig} onChange={(e) => setIg(e.target.value)} />
        </div>
      ) : (
        <div className="field">
          <span className="field-title">Távozás:</span>
          <span className="field-value">{ig}</span>
        </div>
      )}
      {edit ? (
        <div className="field">
          <span className="field-title">Név:</span>
          <input type="text" value={nev} onChange={(e) => setNev(e.target.value)} />
        </div>
      ) : (
        <div className="field">
          <span className="field-title">Név:</span>
          <span className="field-value">{nev}</span>
        </div>
      )}
      {edit ? (
        <div className="field">
          <span className="field-title">Email:</span>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
      ) : (
        <div className="field">
          <span className="field-title">Email:</span>
          <span className="field-value">{email}</span>
        </div>
      )}
      {edit ? (
        <div className="field">
          <span className="field-title">Telefonszám:</span>
          <input type="phone" value={telefonszam} onChange={(e) => setTelefonszam(e.target.value)} />
        </div>
      ) : (
        <div className="field">
          <span className="field-title">Telefonszám:</span>
          <span className="field-value">{telefonszam}</span>
        </div>
      )}
      <div className="field">
        <span className="field-title">Vendégek száma:</span>
        <span className="field-value">{vendegszam}</span>
      </div>
      <div className="field">
        <span className="field-title">Kisállat:</span>
        <span className="field-value">{kisallat}</span>
      </div>
      <div className="field">
        <span className="field-title">Fizetendő:</span>
        <span className="field-value">{fizetendo} ft</span>
      </div>
      <div className="field">
        <span className="field-title">Fizetési Mód:</span>
        <span className="field-value">{paymentMethod}</span>
      </div>
      <div className="icon-container">
        <img onClick={handleEdit} alt="edit" src={editIcon} className="icon editicon" />
        {isChanged && (
          <>
            <img onClick={handleReturnIcon} className="icon returnicon" src={returnIcon} alt="return" />
            <button className="save-button" onClick={handleSave}>MENTÉS</button>
          </>
        )}
        <img onClick={handleDelete} className="icon deleteicon" src={deleteIcon} alt="delete" />
      </div>
    </div>
  );
}
