import "./foglalas.css";
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import "./foglalas_pay.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFormData } from "../store/store";

export function Foglalas_Pay() {
  const dispatch = useDispatch();
  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fizetendo, setFizetendo] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('Bankkártya'); // Új state a fizetési módhoz
  const erkezes = useSelector(state => new Date(state.booking.startDate));
  const tavozas = useSelector(state => new Date(state.booking.endDate));
  const formData = useSelector(state => state.booking.formData);
  const url = process.env.REACT_APP_API_URL + "/foglalas";
  const url1 = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const elementRef = useRef(null);
  const [ejszakaar, setEjszakaar] = useState(0);
  const [kisallatar, setKisallatar] = useState(0);

  function calculateNights(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start >= end) {
      throw new Error('Az érkezési dátumnak korábbinak kell lennie, mint a távozási dátum');
    }

    const diffInMs = end - start;
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return diffInDays;
  }

  const ejszakak = calculateNights(erkezes, tavozas);

  useEffect(() => {
    async function fetchPrice() {
      try {
        const response = await fetch(url1 + '/api/jelenlegi/ar');
        if (!response.ok) {
          throw new Error('Hálózati hiba történt');
        }
        const data = await response.json();
        setPrice(data.napi_dij);
        const nights = calculateNights(erkezes, tavozas);
        let fizetendo = 0;
        if(formData.dezsa=="igen"){
          fizetendo += 10000;
          if(formData.dezsa_futes=="igen"){
            fizetendo += 10000;
          }
        }
        if (formData.pet == "van") {
          if (formData.pet_body_type == "kistestu") {
            fizetendo += nights * 3500;
            setKisallatar(3500);
          } else {
            fizetendo += nights * 5000;
            setKisallatar(5000);
          }
        }
        if (nights < 7) {
          fizetendo += data.napi_dij * formData.guests * nights;
          setFizetendo(fizetendo);
          setEjszakaar(data.napi_dij);
        } else if (nights >= 7 && nights < 14) {
          fizetendo += data.napi_dij * formData.guests * nights * 0.8;
          setFizetendo(fizetendo);
          setEjszakaar(data.napi_dij);
        } else {
          fizetendo += data.napi_dij * formData.guests * nights * 0.7;
          setFizetendo(fizetendo);
          setEjszakaar(data.napi_dij);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchPrice();
  }, [erkezes, tavozas, formData.pet, formData.pet_body_type]);

  const formattedErkezes = new Date(erkezes).toLocaleDateString();
  const formattedTavozas = new Date(tavozas).toLocaleDateString();

  async function handleSubmit(event) {
    event.preventDefault();
    console.log("Form adatok: ", formData);
    const data = {
      email: formData.email,
      guests: formData.guests,
      phone: formData.phone,
      name: formData.name,
      tol: erkezes,
      ig: tavozas,
      pet: formData.pet,
      pettype: formData.pettype,
      pet_body_type: formData.pet_body_type,
      paymentMethod: paymentMethod,
      dezsa: formData.dezsa,
      dezsa_futes: formData.dezsa_futes
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (data.foglalhato === false) {
          alert("Sikertelen foglalás!:(")
          setTimeout(5000);
          navigate("/foglalas");
        } else {
          if(data.paymentMethod == "Készpénz"){
            navigate("/koszonjuk");
            dispatch(setFormData({ paymentMethod: data.paymentMethod }));
            dispatch(setFormData({ fizetendo: data.fizetendo }));
          }
          else if(data.paymentMethod == "SZÉP Kártya"){
            navigate("/koszonjuk/szepkartya");
          dispatch(setFormData({ paymentMethod: data.paymentMethod }));
          dispatch(setFormData({ fizetendo: data.fizetendo }));}
          
          else{
            navigate("/koszonjuk/bankkartya");
            dispatch(setFormData({ paymentMethod: data.paymentMethod }));
            dispatch(setFormData({ fizetendo: data.fizetendo }));
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="fogpay-teljes-container">
      <div ref={elementRef} className="fogpay-szoveg-container">
        <div className="erk-tav">
          <h3>Érkezés: {formattedErkezes}</h3>
          <h3>Távozás: {formattedTavozas}</h3>
        </div>
        <div className="reszletezes">
          <p>{formData.guests} * vendég * {ejszakak} éjszaka * {ejszakaar} ft = <strong>{ejszakak * ejszakaar* formData.guests} ft</strong></p>
          {formData.pet ? <p> {ejszakak} éjszaka * {kisallatar} ft = <strong>{ejszakak * kisallatar} ft</strong> (kisállat miatt felszámított plusz költség)</p> : ""}
          {formData.dezsa=="igen" ?  <p>10.000 ft (dézsa használata){formData.dezsa_futes=="igen" ? <p>10.000ft (dézsa előre befűtése)</p> : ""} </p>: ""}
        </div>
        <h2>Fizetendő összeg:</h2>
        <h4 className="fizetendo">{fizetendo} Ft</h4>
        <form className="payment-form">
          <h3>Válassza ki a fizetési módot:</h3>
          <div>
            <input type="radio" id="bankcard" name="paymentMethod" value="Bankkártya"
              checked={paymentMethod === 'Bankkártya'} onChange={(e) => setPaymentMethod(e.target.value)} />
            <label htmlFor="bankcard">Bankkártya</label>
          </div>
          <div>
            <input type="radio" id="cash" name="paymentMethod" value="Készpénz"
              checked={paymentMethod === 'Készpénz'} onChange={(e) => setPaymentMethod(e.target.value)} />
            <label htmlFor="cash">Készpénz</label>
          </div>
          <div>
            <input type="radio" id="szepcard" name="paymentMethod" value="SZÉP Kártya"
              checked={paymentMethod === 'SZÉP Kártya'} onChange={(e) => setPaymentMethod(e.target.value)} />
            <label htmlFor="szepcard">SZÉP Kártya (MKB, K&H, OTP)</label>
          </div>
          <button type="submit" onClick={handleSubmit}>Foglalok és Fizetek!</button>
        </form>
      </div>
    </div>
  );
}
