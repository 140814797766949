import "./front.css";
import React, { useState, useEffect, useRef } from "react";
import Dezsafurdo from "./fotok/dezsa.jpg";
import Szobakep from "./fotok/szoba.jpg";
import Furdo from "./fotok/furdoszoba.jpg";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setFormData, setDates } from "../../store/store";

export function Front() {
    const url = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const formData = useSelector(state => state.booking.formData);
    const startDate = useSelector(state => new Date(state.booking.startDate));
    const endDate = useSelector(state => new Date(state.booking.endDate));
    const frontContainerRef = useRef(null);
    const frontSzovegRef = useRef(null);
    const frontKepRef = useRef(null);
    const [erkezesDate, seterkezesDate] = useState("");
    const [tavozoDate, settavozoDate] = useState("");

    function handleErkezoDate(e){
        seterkezesDate(e.target.value);
    }

    function handleTavozoDate(e){
        settavozoDate(e.target.value);
    }

    const handleCheckinChange = (e) => {
        dispatch(setDates({ startDate: e.target.value, endDate: endDate }));
    };

    const handleCheckoutChange = (e) => {
        dispatch(setDates({ startDate: startDate, endDate: e.target.value }));
    };

    const handleVendegszamChange = (e) => {
        dispatch(setFormData({ guests: Number(e.target.value) }));
    };

    function isDate(value) {
        const date = new Date(value);
        return !isNaN(date.getTime());
    }

    async function postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    }

    function handleSubmit(e) {
        e.preventDefault();
        
        if(!erkezesDate || !tavozoDate){
            alert("Jelölje be az ÉRKEZÉS és TÁVOZÁS dátumot!");
            return;
        }
        if(erkezesDate >= tavozoDate){
            alert("Az Ön Távozásának később kell történnie mint az Érkezésének.");
            return;
        }
        if (!formData.guests || formData.guests < 1 || formData.guests > 4) {
            alert("Jelölje be a vendégek számát!");
            return;
        }
        dispatch(setDates({ startDate: erkezesDate, endDate: tavozoDate}));
        const most = new Date();
        if (startDate <= most || endDate <= new Date(most.getTime() + 24 * 60 * 60 * 1000)) {
            alert("Visszamenőleg nem foglalhatsz! :(");
            return;
        }
        
        console.log(startDate, endDate, formData.guests);
        if (isDate(startDate) && isDate(endDate) && formData.guests > 0 && formData.guests < 5 && startDate <= endDate) {
            postData(url + '/api/date/check', {
                erkezes: startDate,
                tavozas: endDate,
                vendegszam: formData.guests
            })
                .then(data => {
                    console.log('Sikeres válasz:', data);
                    if (data.foglalhato) {
                        navigate("/foglalas");
                    }
                })
                .catch(error => {
                    alert("Hiba történt!")
                    console.error('Hiba történt:', error);
                });
        }
    }

    const images = [
        Dezsafurdo,
        Furdo,
        Szobakep,
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [images.length]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('visible');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (frontContainerRef.current) {
            observer.observe(frontContainerRef.current);
        }

        if (frontSzovegRef.current) {
            observer.observe(frontSzovegRef.current);
        }

        if (frontKepRef.current) {
            observer.observe(frontKepRef.current);
        }

        return () => {
            if (frontContainerRef.current) {
                observer.unobserve(frontContainerRef.current);
            }

            if (frontSzovegRef.current) {
                observer.unobserve(frontSzovegRef.current);
            }

            if (frontKepRef.current) {
                observer.unobserve(frontKepRef.current);
            }
        };
    }, []);

    return (
        <div ref={frontContainerRef} className="front-container">
            <div ref={frontSzovegRef} className="front-szoveg">
                <h1>Lipics Part Vendégház</h1>
                <div className="p-container">
                    <p>Szakadj ki a városi nyüzsgésből. Kirándulj, fedezd fel Zala-megye tájait és pihenj egy privát házban távol a világ gondjaitól.</p>
                </div>

                <div className="mini-foglalas-container">
  <form>
    <div className="form-group">
      <label className="form-label" htmlFor="erkezes">Érkezés</label>
      <input
        className="form-input"
        name="erkezes"
        type="date"
        value={erkezesDate}
        onChange={handleErkezoDate}
      />
    </div>
    <div className="form-group">
      <label className="form-label" htmlFor="tavozas">Távozás</label>
      <input
        className="form-input"
        name="tavozas"
        type="date"
        value={tavozoDate}
        onChange={handleTavozoDate}
      />
    </div>
    <div className="form-group">
      <label className="form-label" htmlFor="vendegszam">Vendégszám</label>
      <select id="guests" name="guests"  className="form-input" placeholder="Vendégszám" value={formData.guests} onChange={handleVendegszamChange} required>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                        </select>
    </div>
    <button className="form-submit" type="submit" onClick={handleSubmit}>
      Árak & Foglalás
    </button>
  </form>
</div>

            </div>
            <div ref={frontKepRef} className="front-kep" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}></div>
        </div>
    );
}
