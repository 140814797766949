import "./koszonjuk.css";
import React from "react";

export function Koszonjuk() {
    return (
        <div className="koszonjuk-teljes-container">
            <div className="koszonjuk-szoveg-container">
                <h2>Köszönjük a foglalást!</h2>
                <p>
                    Mivel készpénzes fizetést választott, a helyszínen rendezzük a számlát.
                    Ha bármilyen kérdése van, kérjük, keressen minket az elküldött emailben található elérhetőségeken.
                </p>
            </div>
        </div>
    );
}
